export type ExtensionTrackId =
  | "apple_calendar"
  | "github"
  | "google_calendar"
  | "auto_page_creation"
  | "auto_increment_property"
  | "recurring_events"
  | "charts"
  | "sidebar"

type TrackEvent =
  | `${ExtensionTrackId}_extension_card_clicked`
  | "subscribe_button_clicked"
  | "try_it_now_button_clicked"
  | "signup_button_clicked"
  | "login_button_clicked"
  | "recurring_events_extension_execute_button_clicked"
  | "recurring_events_extension_confirm_execution_button_clicked"
  | "see_plans_link_clicked"
  | "get_pro_plan_before_its_too_late"
  | "google_calendar_extension_save_button_clicked"
  | "google_calendar_extension_confirm_save_button_clicked"
  | "get_chart_url_button_clicked"

export function trackEvent(eventName: TrackEvent, callback?: () => void) {
  if (process.env.NODE_ENV === "development") {
    console.log("Track event", eventName)
    callback?.()
  } else {
    // @ts-ignore
    sa_event(eventName, callback)
  }
}
